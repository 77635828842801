import React from "react";
import Boxnavbar from "./component/Nav/boxnavbar";
import Home from "./component/content/pages/Home";
import Contact from "./component/content/Findus/Contact";
import Interact from "./component/content/skid/Interact";
import Commitment from "./component/content/skid/commitment";
import About from "./component/content/Experties/About";
import Footer from "./component/content/Findus/footer";
import Bintogrid from "./component/content/Experties/Bintogrid";

const App = () => {
  return (
    <div className="bg-black ">
      <div className="flex flex-col">
        <div className="flex-1  ">
          <section id="Home" className="h-screen overflow-hidden">
            <h1>
              <Home />
            </h1>
          </section>
          <div className="flex-none  xl:sticky xl:top-0 xl:z-30 sticky top-0 z-30  ">
            <Boxnavbar />
          </div>
          <div className="">
            <section id="About">
              <h1>
                <About />
              </h1>
            </section>
            <Bintogrid/>
            <section id="Interact">
              <h1>
                <Interact />
              </h1>
            </section>
            <Commitment />
            <section id="Contact  " className="bg-white "  >
              <h1 className="">
                <Contact />
                <Footer/>
              </h1>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
