import React, { useState } from 'react';
import { FaPlay, FaPause } from 'react-icons/fa'

const Immerisive = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayClick = () => {
    setIsPlaying(true);
  };

  const handlePauseClick = () => {
    setIsPlaying(false);
  };

  return (
    <div className="flex flex-col items-center w-full space-y-2 py-2 mt-8 shadow-lg bg-[#82828233] bg-opacity-50 backdrop-blur-sm rounded-3xl transition-colors ">
      <div className="flex justify-center w-full mt-2">
        <img
          src="/BEEBOX WEBSITE v2.gif"
          alt="Right Screen"
          className="w-3/4 rounded-3xl"
        />
      </div>
      {isPlaying && (
        <div className="flex justify-center w-full   absolute   left-1/2 transform -translate-x-1/2  xl:absolute   xl:left-1/2 xl:transform xl:-translate-x-1/2">
          <img
            src="/AR watch.gif"
            alt="AR Watch"
            className="xl:w-[75%] xl:rounded-3xl w-3/4 rounded-3xl lg:w-[80%] lg:rounded-3xl"
          />
        </div>
      )}
      <div className="flex justify-start xl:ml-44  2xl:ml-48   md:ml-24 w-full ml-24 ">
        {!isPlaying ? (
          <div
            className="flex items-center text-sm  gap-4 font-lexend justify-center rounded-2xl border border-white  hover:bg-black py-1 px-[7px] hover:text-white text-white cursor-pointer"
            onClick={handlePlayClick}
          >
            play
            <FaPlay className=" text-sm" />
          </div>
        ) : (
          <div
            className="flex items-center text-sm font-lexend justify-center rounded-2xl border border-white  hover:bg-black py-1 px-1 hover:text-white text-white cursor-pointer"
            onClick={handlePauseClick}
          >
            pause
            <FaPause className="mr-2 text-sm" />
          </div>
        )}
      </div>
      <p className="text-xs md:text-sm xl:text-md text-white xl:mt-2  2xl:text-md  2xl:mt-2 font-montserrat  mx-10 text-left">
        Dive into your exclusive content and hallmarks of production.
      </p>
    </div>
  )};

export default Immerisive;
