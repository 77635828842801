import React, { useState, useRef } from "react";
import Slider from "react-slick";
import { FcSettings } from "react-icons/fc";
import ServiceBar from "./ServiceBar";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const mockItems = [
  { id: 0, img: "Sales & Marketing.png", description: "Sales & Marketing" },
  { id: 1, img: "Industrial Training.png", description: "Industrial Training" },
  { id: 2, img: "Maintenance.jpeg", description: "Maintenance Services" },
  { id: 3, img: "sales&support.png", description: "Sales & Support" },
];

function CenterMode() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);

  const handleMenuClick = (slideIndex) => {
    setCurrentSlide(slideIndex);
    sliderRef.current.slickGoTo(slideIndex);
  };

  const handleNext = () => {
    const nextSlide = (currentSlide + 1) % mockItems.length;
    setCurrentSlide(nextSlide);
    sliderRef.current.slickGoTo(nextSlide);
  };

  const handlePrev = () => {
    const prevSlide = (currentSlide - 1 + mockItems.length) % mockItems.length;
    setCurrentSlide(prevSlide);
    sliderRef.current.slickGoTo(prevSlide);
  };

  const settings = {
    className: "center",
    centerMode: true,
    arrows: false, // We are adding custom arrows
    infinite: true,
    centerPadding: "290px", // Default for mobile
    slidesToShow: 1,
    speed: 1000,
    adaptiveHeight: true,
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    responsive: [
      {
        breakpoint: 640, // Mobile screens
        settings: {
          centerPadding: "10px",
        },
      },
      {
        breakpoint: 1024, // Tablet screens
        settings: {
          centerPadding: "50px",
        },
      },
      {
        breakpoint: 1280, // Laptop screens
        settings: {
          centerPadding: "190px",
        },
      },
    ],
  };

  return (
    <div className="w-full md:h-[800px] relative">
      <div className="slider-container w-full mx-auto">
        <div className="rounded-xl w-11/12 mx-auto">
          <div className="pt-2 flex justify-center">
          

            <div className="xl:flex xl:ml-24 2xl:ml-24 gap-2 items-center whitespace-nowrap  justify-center flex ml-3 bg-gray-100 font-montserrat  xl:text-sm text-xs font-semibold xl:py-3 xl:px-5 md:py-4 md:px-9 px-5  py-2 rounded-3xl shadow">
              <i className="text-xl">
                <FcSettings />
              </i>
              Services Oriented
            </div>


            
          </div>
          <ServiceBar
            currentSlide={currentSlide}
            handleMenuClick={handleMenuClick}
          />
        </div>
        <button
          onClick={handlePrev}
          className="absolute z-20 top-[75%] -translate-y-1/2 left-[65px]  -translate-x-full rounded-full border bg-white text-black px-2 py-1 flex items-center xl:top-1/2 xl:left-72 md:left-8"
        >
          &#10094;
        </button>
        <Slider ref={sliderRef} {...settings}>
          {mockItems.map((item) => (
            <div key={item.id}>
              <div className="text-white rounded-2xl px-4 md:px-8 text-center relative">
                <img
                  src={item.img}
                  alt={item.description}
                  className="mx-auto md:w-[90%] md:h-[590px] w-full flex justify-center items-center rounded-2xl"
                />

                
                {/* Hover content */}
                {/* <div className="absolute inset-0 bg-black bg-opacity-60 text-white flex flex-col justify-center items-center opacity-0 hover:opacity-100 transition-opacity duration-300 rounded-2xl">
                  <h3 className="text-lg font-bold">{item.description}</h3>
                  <p className="mt-2">Learn more about our services</p>
                </div> */}
              </div>
            </div>
          ))}
        </Slider>
        <button
          onClick={handleNext}
          className="absolute z-20 top-[75%] -translate-y-1/2 right-[73px] translate-x-full rounded-full border bg-white text-black px-2 py-1 flex items-center xl:right-72 xl:top-1/2 md:right-8"
        >
          &#10095;
        </button>
      </div>
    </div>
  );
}

export default CenterMode;
