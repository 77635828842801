

// import React from 'react';

// const Footer = () => {
//   return (
//     <section className="bg-black">
  //     <head>
  //   <link
  //     href="https://fonts.googleapis.com/css2?family=Bungee+Shade&display=swap"
  //     rel="stylesheet"
  //   />
  //   <link rel="canonical" href="https://wisabo.com/item/5695/new-kansas-regular/"></link>
  // </head>
//       <div className="py-4 px-2 mx-auto w-10/12 sm:py-4 lg:px-6">
//         <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-4 h-full">
//           <div className="col-span-2 sm:col-span-1 rounded-xl md:col-span-2 bg-gray-50 h-auto md:h-64 flex flex-col">
//             <div className="flex justify-center    p-10   bg-white rounded-xl">
//             <div>
//               <p className=" text-black text-center font-montserrat font-semibold text-xl
//               ">With the support of</p>
//               <div className='flex gap-2 text-white mt-4 md:mt-5'>

//              <img src="/IITMIC.png" alt="Trust" className="w-12 h-12 md:w-32 md:h-32 rounded-full" />
//              <img src="/RTBI.png" alt="Trust" className="w-12 h-12 md:w-32 md:h-32 rounded-full" />
//              <img src="/IITMRP.png" alt="Trust" className="w-12 h-12 md:w-32 md:h-32 rounded-full" />
//            </div>
//             </div>
//             </div>
//           </div>
//           <div className=" h-auto md:h- flex flex-col ">
//           <div className="flex  ">
//             <div className="bg-[#FFB833] p-4  rounded-lg  h-64 flex-col justify-center items-center  w-96  ">
     
// <div className='flex flex-col justify-center items-center  mt-14'>

//             <h2
//                     className="   flex  text-4xl  font-newKansas  text-amber-950 sm:text-5xl sm:leading-none md:text-[45px]">
//                    Making  every
//                 </h2>
               
                   
//                       <div className='flex   text-4xl items-center gap-1'>

//             <h2
//                     className="text-4xl  font-bold bungee-shade-regular  text-gray-900 sm:text-5xl sm:leading-none md:text-5xl">
//                     BUZZ
//                 </h2>
//                     <span className="font-newKansas text-[44px]  text-amber-950 ">count.</span>
//                       </div>
//                       </div>
             
//             </div>
//           </div>
//           </div>
//           <div className="col-span-2 gap-2 sm:col-span-1 md:col-span-2 shadow-lg backdrop-blur-[89.3px]  space-y-4">
//           <div className='bg-white/10  flex flex-col justify-center  py-7  px-56 items-center shadow-lg backdrop-blur-[89.3px] rounded-xl border border-white/50'>
//               <p className="font-semibold text-center text-white whitespace-nowrap font-montserrat">Find us at</p>
//               <div className="mt-4 space-x-8 flex items-center justify-center text-white ">
//                 <a href="#" className="py-1 px-2 border border-white rounded-full"><i className="fas fa-phone"></i></a>
//                 <a href="#" className="py-1 px-2 border border-white rounded-full"><i className="fab fa-linkedin"></i></a>
//                 <a href="#" className="py-1 px-2 border border-white rounded-full"><i className="fas fa-envelope"></i></a>
//                 <a href="#" className="py-1 px-2 border border-white rounded-full"><i className="fab fa-twitter"></i></a>
//               </div>
//             </div>
//             <div className='bg-white/10 text-white w-full mx-auto py-6 flex justify-between  items-center shadow-lg backdrop-blur-[89.3px] rounded-xl border border-white/50'>

// <div className="mt-4 text-sm w-1/2 font-lexend mx-8  ">&copy; 2024 All Rights Reserved, <span>BeeBox Studios Private Limited.</span></div>
// <div className='w-1/2 flex justify-center'>


// <a href="#" className="text-sm underline hover:text-blue-400">Privacy Policy</a>
// </div>
// </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Footer;


import React from 'react';

const Footer = () => {
  return (
    <section className="bg-black text-white">
      <head>
    <link
      href="https://fonts.googleapis.com/css2?family=Bungee+Shade&display=swap"
      rel="stylesheet"
    />
    <link rel="canonical" href="https://wisabo.com/item/5695/new-kansas-regular/"></link>
  </head>
      <div className="py-8 px-7 mx-auto w-11/12 lg:w-10/12">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-6    ">
          
          {/* Support Section */}
          <div className="col-span-2 sm:col-span-1 md:col-span-2 bg-gray-50 h-auto md:h-64 flex flex-col rounded-xl">
            <div className="flex justify-center items-center p-8 bg-white rounded-xl">
              <div>
                <p className="text-black text-center font-montserrat font-semibold text-xl">With the support of</p>
                <div className="flex justify-center gap-4 mt-5">
                  <img src="/IITMIC.png" alt="IITMIC" className="w-12 h-12 md:w-32 md:h-32 rounded-full" />
                  <img src="/RTBI.png" alt="RTBI" className="w-12 h-12 md:w-32 md:h-32 rounded-full" />
                  <img src="/IITMRP.png" alt="IITMRP" className="w-12 h-12 md:w-32 md:h-32 rounded-full" />
                </div>
              </div>
            </div>
          </div>

          {/* Buzz Section */}
          <div className="col-span-2 md:col-span-1 bg-[#FFB833] p-6 rounded-xl flex items-center justify-center">
            <div className="flex flex-col items-center">
              <h2 className="text-4xl font-newKansas text-amber-950 text-center">Making every</h2>
              <div className="md:flex flex-col   items-center md:gap-2 text-center">
                <h2 className="text-4xl font-bold bungee-shade-regular text-gray-900">BUZZ</h2>
                <span className="font-newKansas text-[44px] text-amber-950">count.</span>
              </div>
            </div>
          </div>

          {/* Contact Section */}
          <div className="col-span-2 sm:col-span-1 md:col-span-2 space-y-9">
            <div className="bg-white/10 p-8 flex flex-col items-center rounded-xl border border-white/50 shadow-lg backdrop-blur-lg">
              <p className="font-semibold text-white font-montserrat">Find us at</p>
              <div className="mt-4 flex space-x-6">
                <a href="tel:+91-875-448-3451" className="py-2 px-3 border border-white rounded-full"><i className="fas fa-phone"></i></a>
                <a href="https://www.linkedin.com/company/beebox-studios/posts/" className="py-2 px-3 border border-white rounded-full"><i className="fab fa-linkedin"></i></a>
                <a href="mailto:info@beeboxstudios.com" className="py-2 px-3 border border-white rounded-full"><i className="fas fa-envelope"></i></a>
              </div>
            </div>
            
            <div className="bg-white/10 py-6 flex justify-between items-center rounded-xl border border-white/50 shadow-lg backdrop-blur-lg px-8">
              <div className="text-sm font-lexend">&copy; 2024 All Rights Reserved, <span>BeeBox Studios Private Limited</span>.</div>
              <a href="https://www.beeboxstudios.com/privacy-policy" className="text-sm underline hover:text-blue-400">Privacy Policy</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
