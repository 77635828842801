import React from 'react';
import { PiStarFourFill } from "react-icons/pi";
import Carouselservices from './services';
import Carouselindus from './Carouselindus';

const About = () => {
    return (
    <div id="About" className="scroll-smooth overflow-hidden">
      <div className="bg-white ">
        <div className="xl:w-full flex flex-col justify-start items-start text-start pl-5 gap-2 xl:pt-16 pt-5 w-6/12 md:pt-  xl:pl-32">
        

          <div className="xl:flex xl:ml-24 2xl:ml-1 gap-2 items-center whitespace-nowrap  justify-center flex ml-3 bg-gray-100 font-montserrat  xl:text-sm text-xs font-semibold xl:py-3 xl:px-5 md:py-4 md:px-9 px-5  py-2 rounded-3xl shadow">
              <i className='text-lg text-amber-400'>
                <PiStarFourFill />
              </i>
              OUR EXPERTISE
            </div>
          <h1 className="xl:flex xl:text-2xl md:text-xl text-md whitespace-nowrap  font-semibold md:whitespace-nowrap  xl:whitespace-nowrap font-lexend text-black">
              Explore our curated portfolio
          </h1>
        </div>
   
      </div>
      <div className="">

        <Carouselservices />
      </div>
      <div className="">
        <Carouselindus />
      </div>
    </div>
  );
};

export default About;
