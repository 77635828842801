import React, { useState } from "react";
import { BsWhatsapp } from "react-icons/bs";
import { PiStarFourFill } from "react-icons/pi";
import { FaArrowRight } from "react-icons/fa6";
import axios from "axios";

const Contact = () => {
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [currentUser, setCurrentUser] = useState("");

  const [formData, setFormData] = useState({
    name: "from website",
    email: "",
    phone: "0000000000",
    message: "Mail from beebox contact",
  });

  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!formData.email.trim()) {
      formErrors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Email is invalid";
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);

    
      try {
        await axios.post('https://www.beeboxstudios.com/contactinfo', formData);
    
        setIsSent(false);
        setFormData({ name: "Test",
          email: "",
          phone: "0000000000",
          message: "Mail from beebox contact", });
      } catch (error) {
        console.error("Error submitting the form", error);
      } finally {
        setIsSubmitting(false);
      }
    
    
  };

  return (
    <div id="Contact" className="flex  justify-center  w-full mx-auto ">
      <section
        action="https://www.beeboxstudios.com/contactinfo"
        className="container mx-auto px-4 py-12 my-20 w-10/12 rounded-3xl bg-customOrange"
      >
        <div className="gap-2 mb-1 flex-none w-40 whitespace-nowrap flex justify-start items-start bg-gray-100 font-montserrat xl:text-sm text-xs font-semibold xl:py-2 xl:px-3 md:py-4 md:px-9 px-5 py-2 rounded-3xl shadow">
          <i className="text-lg text-amber-400">
            <PiStarFourFill />
          </i>
          OUR CONTACT
        </div>
        <div className="flex flex-col md:flex-row items-center justify-between ">
          {/* Left Side: Company Info */}
          <div className="w-full md:w-1/2 md:mb-0">
            <div className="relative">
              <img src="/MacBook Mockup.png" alt="image" className="w-full" />
              <div className="absolute top-[3.7%] left-[9.1%] w-[82%] h-[86.3%] overflow-hidden">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.6996041325538!2d80.24036047453232!3d12.991055214452494!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a526787f95d413d%3A0x3af382f9e2662992!2sBeebox%20Studios%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1720426701329!5m2!1sen!2sin"
                  width="200"
                  height="450"
                  loading="lazy"
                  title="Google Maps"
                  className="w-full"
                ></iframe>
              </div>
            </div>
          </div>

          {/* Right Side: Details and Buttons */}
          <div className="w-full md:w-1/2 mx-auto flex flex-col justify-center items-center">
            <div className="px-8 py-6 bg-white/40 shadow-lg backdrop-blur-[89.3px] rounded-3xl w-11/12 max-w-md mt-2">
              <h1 className="text-3xl font-semibold text-center text-white">
                We’d love to Get <br /> connected
              </h1>

              <form
                action="https://www.beeboxstudios.com/contactinfo"
                method="post"
                onSubmit={handleSubmit}
                className="flex my-6"
              >
                <input
                  name="email"
                  type="email"
                  autoComplete="email"
                  placeholder="Type in your email address"
                  className="text-start py-2 px-4 w-full mx-auto rounded-l-lg bg-white font-montserrat focus:text-slate-300 text-slate-400 placeholder-slate-400 focus:ring-0 focus:outline-none"
                  required
                  value={formData.email} // Bind to formData.email
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  } 
                />
   <button
                  type="submit"
                  className={`py-3 px-5 text-white font-semibold rounded-r-lg shadow-md ${
                    isSubmitting ? "bg-black cursor-not-allowed" : "bg-black"
                  }`}
                  disabled={isSubmitting}
                >
                  <i className="text-white">
                    <FaArrowRight />
                  </i>
                </button>
              </form>
            </div>
            <div className="bg-white/40 shadow-lg backdrop-blur-[89.3px] rounded-3xl w-11/12 max-w-md mt-2">
              <div className="px-8 py-6">
                <div className="flex flex-col justify-center items-center">
                  <a
                    href="https://api.whatsapp.com/send/?phone=918754483451&amp;text=Hello+Beebox,+I+would+like+to+get+more+information&amp;type=phone_number&amp;app_absent=0"
                    title="Whatsapp"
                    className="flex items-center gap-2 text-white text-sm md:text-base"
                  >
                    {/* <BsWhatsapp className="text-2xl text-green-500" /> */}
                    {/* Chat on WhatsApp */}
                  </a>
                  <div className="bg-white rounded-xl flex flex-col justify-center items-center mt-4 px-4 py-6">
                    <img
                      src="/beeboxscanner1.jpeg"
                      alt="Beebox Scanner"
                      className="w-24 h-24 md:w-44 md:h-44 rounded-xl"
                    />
                    <h1 className="text-black text-xs text-center font-montserrat font-semibold mt-2">
                      Reach out on WhatsApp
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
