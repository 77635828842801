import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import Rightscreen from "./rightscreen";
import Diamond from './diamond';
import Immerisive from './immerisive';
import ArrowComponent from './Arrow';

const Carousel = () => {
  const items = [
    <Diamond key="card1" />,
    <Immerisive key="card2"/>,
    <Rightscreen key="card3" />,
  ];

  const [currIndex, setCurrIndex] = useState(1); 
  const sliderRef = useRef(null);
  const itemRefs = useRef([]);

  const resize = () => {
    if (itemRefs.current.some(item => item === null)) return;

    let width;
    if (window.innerWidth >= 1280) { // xl
      width = Math.min(window.innerWidth * 0.4, 850);
    } else if (window.innerWidth >= 1024) { // lg
      width = Math.min(window.innerWidth * 0.5, 750);
    } else if (window.innerWidth >= 768) { // md
      width = Math.min(window.innerWidth * 0.6, 650);
    } else { // sm
      width = Math.min(window.innerWidth * 0.8, 450);
    }
    const height = window.innerHeight * 0.8; 
    const totalWidth = width * items.length;

    sliderRef.current.style.width = `${totalWidth}px`;

    itemRefs.current.forEach((item) => {
      if (item) {
        item.style.width = `${width - 30}px`;
        item.style.height = `${height}px`;
      }
    });

    move(currIndex);
  };

  const move = (index) => {
    if (index < 0) index = items.length - 1;
    if (index >= items.length) index = 0;
    setCurrIndex(index);

    itemRefs.current.forEach((item, i) => {
      if (item) {
        const box = item.querySelector('.item__3d-frame');
        if (i === index) {
          item.classList.add('carousel__slider__item--active');
          box.style.transform = 'perspective(1200px)';
        } else {
          item.classList.remove('carousel__slider__item--active');
          box.style.transform = `perspective(1200px) rotateY(${
            i < index ? 40 : -40
          }deg)`;
        }
      }
    });

    if (itemRefs.current[0]) {
      sliderRef.current.style.transform = `translate3d(${
        index * -itemRefs.current[0].offsetWidth + window.innerWidth / 2 - itemRefs.current[0].offsetWidth / 2
      }px, 0, 0)`;
    }
  };

  const handleSliderChange = (event, newValue) => {
    move(newValue - 1);
  };

  useEffect(() => {
    resize();
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  return (
    <div className="xl:flex xl:justify-center flex justify-center items-center  xl:mt-14  mt-40 scroll-smooth">
      <div className=' absolute xl:top-28  md:top-28 flex justify-center   top-36'>
      <img src="/Web bar.png" alt="webbar" className="xl:w-4/5 w-5/6" />
      
      </div>
      <div className="w-full max-w-8xl elative">
        <div
          className="relative transition-transform duration-1000 ease-in-out flex justify-start  overflow-hidden"
          ref={sliderRef}
        >
          {items.map((item, index) => (
            <div
              key={index} 
              className="relative float-left   justify-center"
              ref={(el) => (itemRefs.current[index] = el)}
            >
              <div className="relative w-full h-full transition-transform duration-1000 transform-style-3d item__3d-frame">
                {item}
              </div>
            </div>
          ))}
        </div>
        <div className="xl:absolute xl:w-full flex justify-center items-center right-1/2 transform translate-x-1/2 xl:bottom-8 xl:left-96 mx-auto w-full  bg-white rounded-3xl h-5 absolute bottom-44 max-w-72 md:max-w-sm xl:max-w-md md:absolute md:bottom-52">
        <div className="chevron chevron-left"></div>
          <Box sx={{ width: '100%' }} className="">
            <Stack spacing={1} direction="row" sx={{ mb: 1 }} alignItems="center">
              <Slider
                className="absolute bottom-2 sm:absolute top-1"
                aria-label="Carousel Slider"
                value={currIndex + 1}
                min={1}
                max={items.length}
                onChange={handleSliderChange}
                sx={{
                  height: 2,
                  '& .MuiSlider-thumb': {
                    background: ' #bfbfbf',
                    borderRadius: '50%',
                    height: 25,
                    width: 25,
                  },
                  '& .MuiSlider-track': {
                    height: 2,
                    color: 'black',
                  },
                  '& .MuiSlider-rail': {
                    height: 2,
                    color: 'black',
                  },
                }}
              />
            </Stack>
          </Box>
          <div>
            
          </div>
          
          <div className=''>

          <ArrowComponent/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
