import React from 'react';
import { PiStarFourFill } from "react-icons/pi";
import Lottie from "lottie-react";
import map_lottie from './skid/beebox-map.json';
import Gradient from './skid/gradient.json';

const Map = () => {
  return (


    <div className="relative w-10/12  mx-auto mt-20 bg-black overflow-hidden rounded-2xl">
    <div className='absolute -top-60 left-0 w-1/2 h-1/2 md:w-1/3 md:h-1/3 xl:w-1/4 xl:h-1/4 pointer-events-none backdrop-blur-[89.3px] opacity-90 filter blur-3xl bg-black/20'>
          <Lottie animationData={Gradient} loop={true} className='w-[1700px]'/>
        </div>

        <Lottie animationData={map_lottie} loop={true} className="h-[450px] max-w-full " />
        {/* Our Footprint Button */}
        <div className='absolute top-10 left-12 md:left-48 md:top-11 xl:left-56 2xl:left-[34px] 2xl:top-11'>
          <button className="flex gap-2 items-center bg-gray-100 font-montserrat text-black text-xs md:text-sm font-semibold px-1 py-1 md:py-2 md:px-2 rounded-3xl shadow">
            <PiStarFourFill className='text-sm md:text-lg xl:text-md 2xl:text-md text-amber-400' />
            OUR FOOTPRINT
          </button>
        </div>
        
        <div className='absolute bottom-11 right-12 md:right-48 xl:right-56 2xl:right-[34px] flex justify-end'>
          <a
            href="#Contact"
            className="flex items-center hover:bg-white hover:text-black font-lexend font-semibold text-white border border-white py-2 px-6 gap-2 rounded-3xl inline-flex"
          >
            <span>Let's Partner</span>
            <svg
              className="w-4"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
            >
              <path d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
            </svg>
          </a>
        </div>
</div>

  );  
}

export default Map;
