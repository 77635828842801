import React, { useState, useEffect } from 'react';
import { HiMenu, HiX } from 'react-icons/hi';

const sections = [
  { id: 'Home', label: 'HOME' },
  { id: 'About', label: 'ABOUT' },
  { id: 'Interact', label: 'INTERACT' },
];

const Navbar = () => {
  const [selectedItem, setSelectedItem] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      let currentSection = '';

      sections.forEach(section => {
        const element = document.getElementById(section.id);
        const rect = element.getBoundingClientRect();

        if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {
          currentSection = section.id;
        }
      });

      setSelectedItem(currentSection);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && !event.target.closest('.mobile-menu')) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative scroll-smooth">
      <div className="flex items-center justify-between mx-5 xl:mx-36 xl:py-4 py-2">
        <div className="">
          <div className='bg-white/10 shadow-md rounded-3xl transition-colors backdrop-blur-xl md:py-0'>
            <div className="flex items-center lg:gap-3 gap-2 px-5 py-2  ">
              <img 
                src='https://www.beebox3d.com/assets/images/logo1.png' 
                className="lg:w-12 lg:h-12 w-6 h-6" 
                alt="Logo"
              />
              <div className="text-white w-full md:text-xl lg:text-xl xl:text-2xl flex gap-2 text-sm font-montserrat font-bold">
                <span>Beebox</span> <span>Studios</span>
              </div>
            </div>
          </div>
        </div>
        <div className="xl:hidden block md:relative">
          <button 
            className="text-2xl text-white font-bold" 
            onClick={toggleMenu}
          >
            {isOpen ? <HiX /> : <HiMenu />}
          </button>
          <div 
            className={`fixed top-0 left-0 h-full w-1/2 bg-white/20 shadow-lg transform transition-transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} z-50`} 
            style={{ transition: 'transform 300ms ease-in-out' }}
          >
            <div className="flex justify-center my-5 md:justify-between">
              <img 
                src='https://www.beebox3d.com/assets/images/logo1.png' 
                className="w-12 h-12" 
                alt="Logo"
              />
            </div>
            <nav className="mobile-menu flex flex-col items-center  h-full">
              <ul className="space-y-4">
                {sections.map(section => (
                  <li key={section.id}>
                    <a
                      href={`#${section.id}`}
                      className={`block text-white px-5 py-2 font-semibold rounded-2xl ${selectedItem === section.id ? 'border border-slate-500' : ''}`}
                      onClick={() => {
                        setSelectedItem(section.id);
                        setIsOpen(false);
                      }}
                    >
                      {section.label}
                    </a>
                  </li>
                ))}
                <li>
                  <a
                    href="#Contact"
                    className="block px-4 py-2 text-white font-semibold hover:bg-orange-400 hover:text-black/50 rounded-lg cursor-pointer"
                    onClick={() => {
                      setSelectedItem('Contact');
                      setIsOpen(false);
                    }}
                  >
                    CONTACT
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <nav className="hidden xl:flex xl:items-center backdrop-blur-xl xl:py-5 xl:justify-end xl:px-5 md:rounded-3xl xl:bg-white/10">
          <ul className="flex space-x-4 font-semibold font-montserrat text-sm md:text-lg">
            {sections.map(section => (
              <li key={section.id}>
                <a
                  href={`#${section.id}`}
                  className={`text-white px-5 py-1 font-semibold rounded-2xl hover:text-black/50 ${selectedItem === section.id ? 'border border-slate-500' : ''}`}
                  onClick={() => {
                    setSelectedItem(section.id);
                  }}
                >
                  {section.label}
                </a>
              </li>
            ))}
            <li>
              <a
                href="#Contact"
                className="px-4 py-3 text-white hover:bg-customOrange hover:text-black rounded-xl cursor-pointer"
                onClick={() => {
                  setSelectedItem('Contact');
                }}
              >
                CONTACT
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
