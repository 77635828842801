import React from 'react';
import { PiStarFourFill } from "react-icons/pi";
import Clients from '../Clients';
import Map from '../map';

const Commitment = () => {
  return ( 
    <div id="Commitment" className='bg-white mx-auto mt-64 md:mt-64 scroll-smooth'>
      <div className='w-full  items-start px-4  py-2 xl:pl-[120px]'>
        <button
          className="flex gap-2 items-center bg-gray-100  font-montserrat text-black text-sm font-semibold md:my-7 md:py-2 px-2  py-3 md:px-5  rounded-3xl shadow"
        >
          <i className='items-center text-lg whitespace-nowrap text-amber-400'>
            <PiStarFourFill />
          </i>
          OUR COMMITMENT
        </button>

        
        <h1 className='flex  text-xl md:text-3xl pt-2 md:pt-4 font-lexend text-black/80 font-medium'>
          Collaborated with Industry Leaders
        </h1>
      </div>
      <div >
        <Clients/>
      </div>
      <div>
        <Map/>
      </div>
    </div>
  );
}

export default Commitment;
